import consumer from "./consumer"

consumer.subscriptions.create("OrderChannel", {
  connected() {

  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    $('.dataTables_empty').remove()
    $('.orders_table tbody tr:first').after(data.html);
    alert('New Order(s) Received!')
  }
});
