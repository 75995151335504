import { Controller } from "stimulus";

export default class extends Controller {
  updateRow(event) {
    const [_data, _status, xhr] = event.detail;

    // Parse the response
    const responseHtml = xhr.response;

    // Extract the row ID from the event or dataset
    const rowId = event.target.closest("tr").id;

    // Locate the row in the DOM
    const rowElement = document.getElementById(rowId);

    if (rowElement) {
      // Replace the row's HTML with the updated HTML
      rowElement.innerHTML = responseHtml;

      // Refresh DataTable's cache for the updated row
      const table = $("#dataTable").DataTable();
      table.row(document.getElementById(rowId)).invalidate(); // Target the row containing the updated cell
      table.draw(); // Re-draw the table to reflect changes
    } else {
      console.error(`Row with ID ${rowId} not found.`);
    }

    this.reinstantiateTooltips();
  }

  reinstantiateTooltips() {
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      var tooltip = new bootstrap.Tooltip(tooltipTriggerEl);
      tooltipTriggerEl.addEventListener("click", function () {
        tooltip.hide();
      });
    });
  }
}
